import React from "react";
import { Dropdown, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import img1 from "./ColJuLogo.gif";

const NavBar = (props) => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src={img1} style={{ width: "40%" }} alt="ColJuridicaLogo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul className="navbar-nav mr-auto">
            <Nav.Link>
              <Link
                to="/"
                className="text-secondary font-weight-bold text-decoration-none"
              >
                Inicio{" "}
              </Link>
            </Nav.Link>

            <Nav.Link>
              <Link
                to="/nosotros"
                className="text-secondary font-weight-bold text-decoration-none"
              >
                Quienes Somos
              </Link>
            </Nav.Link>

            <NavDropdown title="Servicios" id="collasible-nav-dropdown">
              <Dropdown.Item>
                <Link
                  to="/civil"
                  className="text-secondary font-weight-bold text-decoration-none"
                >
                  <p className="font-weight-bold">Civil</p>
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link
                  to="/penal"
                  className="text-secondary font-weight-bold text-decoration-none"
                >
                  <p>Penal</p>
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link
                  to="/administrativo"
                  className="text-secondary font-weight-bold text-decoration-none"
                >
                  <p>Administrativo</p>
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link
                  to="/laboral"
                  className="text-secondary font-weight-bold text-decoration-none"
                >
                  <p>Laboral</p>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link
                  to="/familia"
                  className="text-secondary font-weight-bold text-decoration-none"
                >
                  <p>Familia</p>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link
                  to="/tributario"
                  className="text-secondary font-weight-bold text-decoration-none"
                >
                  <p>Tributario</p>
                </Link>
              </Dropdown.Item>
            </NavDropdown>
            <Nav.Link>
              <Link
                to="/consultoriojuridico"
                className="text-secondary font-weight-bold text-decoration-none"
              >
                <p>Consultorio Juridico</p>
              </Link>
            </Nav.Link>
          </ul>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
