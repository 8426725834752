import React from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import img1 from "./penalCol.jpg";
const Penal = (props) => {
  return (
    <>
      <h2 className="mt-5 mb-5">DERECHO PENAL</h2>

      <Container>
        <div>
          <p className="text-start">
            El Derecho Penal es del Derecho Público por excelencia, pues en el
            mismo se considera afectada la Sociedad en sus más amplios términos.
            El hecho de encontrarse involucrado en la comisión de un delito o
            falta, ya como víctima o ya como acusado, supone siempre una
            situación interspectiva y desagradable pues afecta a los bienes más
            fundamentales de la persona. Es por esa razón que estamos ante una
            de las materias que necesita con mayor razón, el asesoramiento de
            especialistas, que asuman la defensa de tales bienes y derechos y
            nos representen ante los Tribunales de Justicia.
          </p>

          <p className="text-start">
            El Derecho Penal engloba delitos de la más variada índole, son
            quizás los delitos contra las personas (homicidios, asesinatos,
            agresiones sexuales, lesiones, malos tratos, etc) los que producen
            una mayor alarma social y los que van acompañados de situaciones
            personales más delicadas, pero también nos afectan muy directamente
            los delitos relacionados con el patrimonio (hurtos, robos,
            estafas...) que dañan nuestra esfera económica y con ello también
            nuestras vidas; además de los anteriores, hay un numeroso elenco de
            delitos de muy distinta naturaleza, en los que usted puede verse
            involucrado y en los que necesitarán la asistencia de profesionales
            que defiendan sus derechos.
          </p>

          <p className="text-start">
            También dentro de esta rama de derecho se castigan las faltas,
            consideradas como "delitos menores" pero que son perseguibles ante
            los Tribunales y susceptibles de sanción. De igual forma, ciertas
            actuaciones imprudentes, que originan accidentes (por ejemplo en la
            Circulación de Vehículos a Motor) y producen graves y trágicas
            consecuencias, pueden ser constitutivas de infracción penal, a veces
            como delito y en otras ocasiones como falta.
          </p>

          <p className="text-start">
            Desde nuestro despacho encontrará profesionales que asuman su
            defensa cuando se vea involucrado e imputado en la comisión de
            cualquier delito y falta, así como abogados que le asistirán
            judicialmente a la hora de formular acusaciones. Estas acusaciones
            pueden hacerse en forma de: a) "Querella", que consiste en poner en
            conocimiento de la autoridad judicial la comisión de un delito, en
            nuestra condición de víctima del mismo, personándonos en el
            procedimiento como acusación particular o, b) en forma de "Denuncia"
            en la que se pone en conocimiento de la autoridad judicial o
            policial unos hechos que se considera pueden ser constitutivos de
            delito, pero sin intervenir en el aseguramiento del delito o falta
            denunciada.
          </p>
        </div>

        <img
          className="m-3 shadow-sm p-3 mb-5"
          src={img1}
          style={{ width: "50%" }}
          alt="imagen1"
        />
        <Container className="m-3">
          <Link to="/consultoriojuridico">
            <Button>Contactenos</Button>
          </Link>
        </Container>
      </Container>
    </>
  );
};
export default Penal;
