import React from "react";
import { Button, Container } from "react-bootstrap";
import img1 from "./adminis.jpg";
import { Link } from "react-router-dom";

const Administrativo = (props) => {
  return (
    <>
      <div className="container w-75">
        <div>
          <h2 className="mt-5 mb-5">DERECHO ADMINISTRATIVO</h2>
          <p className="text-start mb-5">Nuestros servicios incluyen:</p>

          <Container>
            <div className="row align-items-start">
              <div className="col">
                <ul className="text-start">
                  <li>
                    Acciones contra el Estado: nulidades, restablecimientos del
                    derecho, contractuales, reparaciones directas, acciones de
                    cumplimiento, entre otras.
                  </li>
                  <li>Asistencia legal en licitaciones y concursos</li>
                  <li>Asesoría en contratación estatal</li>
                  <li>Conciliaciones y arbitramentos</li>
                  <li>Liquidación de contratos</li>
                  <li>Reclamaciones administrativas</li>
                  <li>
                    Recursos administrativos y agotamiento de la vía gubernativa
                  </li>
                  <li>
                    Controversias contractuales ante la jurisdicción contencioso
                    administrativa
                  </li>
                  <li>Uniones temporales y consorcios</li>
                  <li>Procesos de responsabilidad fiscal</li>
                </ul>
              </div>
              <div className="col">
                <img style={{ width: "500px" }} src={img1} alt="imagenad" />
              </div>
            </div>
          </Container>
        </div>
        <Link to="/consultoriojuridico">
          <Button>Contactenos</Button>
        </Link>
      </div>
    </>
  );
};

export default Administrativo;
