import React from "react";
import { Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Map from "../Map/Map";
import img1 from "./asesor.jpg";
import img2 from "./notaria.jpeg";
import img3 from "./outso.jpg";
import img4 from "./cartera.jpg";
import img5 from "./rama.jpg";
import "./Portafolio.css";
const Portafolio = () => {
  return (
    <Container>
      <h2 className="p-5">PORTAFOLO DE SERVICIOS</h2>

      <div className="d-flex flex-wrap justify-content-around mb-5">
        <Card className="carta m-2" style={{ width: "18rem" }}>
          <Link to="/civil" className="text-dark text-decoration-none">
            <Card.Img className="header-img" variant="top" src={img1} />
            <Card.Body>
              <Card.Title>ASESORIA JURIDICA INTEGRAL</Card.Title>
              <Card.Text>
                COLJURIDICA CONTINENTAL LTDA. brinda un Estudio Jurídico, cuyo
                principal espectro de trabajo, está dirigido al Asesoramiento de
                Personas y Empresas en general.
              </Card.Text>
            </Card.Body>
          </Link>
        </Card>

        <Card className="carta m-2" style={{ width: "18rem" }}>
          <Link to="/penal" className="text-dark text-decoration-none">
            <Card.Img variant="top" src={img2} />
            <Card.Body>
              <Card.Title>PROCESOS NOTARIALES</Card.Title>
              <Card.Text>
                En el área de derecho notarial, se tiene un enfoque en las leyes
                aplicables a los comerciantes, como también las actividades
                comerciales, como contratos, compraventas y transacciones de
                bienes raíces.
              </Card.Text>
            </Card.Body>
          </Link>
        </Card>

        <Card className="carta m-2" style={{ width: "18rem" }}>
          <Link to="/administrativo" className="text-dark text-decoration-none">
            <Card.Img variant="top" src={img3} />
            <Card.Body>
              <Card.Title>OUTSOURCING</Card.Title>
              <Card.Text>
                Consientes de la recesión económica y de los gastos inherentes a
                la conformación de un departamento jurídico interno, COLJURIDICA
                CONTINENTAL LTDA ofrece sus servicios jurídicos outsourcing,
                para dar una solución eficaz y económica a esta necesidad,
                permitiéndoles a nuestros clientes enfocar sus esfuerzos al
                desarrollo de su objeto social.
              </Card.Text>
            </Card.Body>
          </Link>
        </Card>

        <Card className="carta m-2" style={{ width: "18rem" }}>
          <Link to="/laboral" className="text-dark text-decoration-none">
            <Card.Img variant="top" src={img4} />
            <Card.Body>
              <Card.Title>RECUPERACION DE CARTERA</Card.Title>
              <Card.Text>
                COLJURIDICA CONTINENTAL LTDA, pone en funcionamiento su
                experiencia en el recaudo de cartera, ejecutando desde el primer
                momento una gestión de cobro y calificación de los créditos de
                acuerdo a criterios de recuperabilidad y problemática del
                deudor, evitando gastos de cobro innecesarios para su empresa
              </Card.Text>
            </Card.Body>
          </Link>
        </Card>
      </div>

      <Container className="bg-light p-5">
        <div className="row">
          <h5 className="p-3">LINKS DE INTERES</h5>
          <div className="col">
            <img src={img5} style={{ width: "20%" }} alt="logoRamaJudicial" />
          </div>
          <div className="col">
            <ul className="list-unstyled">
              <li className="sectionB">
                <a
                  className="text-decoration-none"
                  href="https://www.ramajudicial.gov.co/directorio-cuentas-de-correo-electronico"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Directorio de Correos Electrónicos
                </a>
              </li>
              <li className="sectionB">
                <a
                  className="text-decoration-none"
                  href="https://consultaprocesos.ramajudicial.gov.co/procesos/bienvenida"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Consulta de Procesos
                </a>
              </li>
              <li className="sectionB">
                <a
                  className="text-decoration-none"
                  href="https://directoriojudicial.ramajudicial.gov.co/Sierju-Web/app/consultaExternaDespachos-flow?execution=e1s1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Directorio Nacional
                </a>
              </li>
              <li className="sectionB">
                <a
                  className="text-decoration-none"
                  href="https://www.ramajudicial.gov.co/registro-nacional-de-abogados"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Registro Nacional de Abogados
                </a>
              </li>
              <li className="sectionB">
                <a
                  className="text-decoration-none"
                  href="https://jurisprudencia.ramajudicial.gov.co/WebRelatoria/consulta/index.xhtml"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Consulta de Jurisprudencia - Altas Cortes
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <Container className="m-4">
        <h2 className="p-3">Nos ubicamos en: </h2>
        <Map />
      </Container>
    </Container>
  );
};

export default Portafolio;
