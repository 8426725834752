import React from "react";
import { ContactUs } from "../ContactUs/ContactUs";


const Consultorio = () => {
  return (
    <>
      <ContactUs />
    </>
  );
};

export default Consultorio;
