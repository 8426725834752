import React from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const FooterWeb = () => {
  return (
    <Container className="mt-5">
      <footer className="text-center text-lg-start bg-light text-muted">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>
              Para mayor información, contáctanos a través de nuestra línea
              directa o solicité información por medio nuestro link de
              contactenos.
            </span>
          </div>
        </section>

        <section>
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3">
                    COLJURIDICA CONTINENTAL LTDA.
                  </i>
                </h6>
                <p>Soluciones de LEY a su alcance.</p>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">SERVICIOS</h6>
                <p>
                  <Link
                    to="civil"
                    className="text-secondary text-decoration-none"
                  >
                    Civil
                  </Link>
                </p>
                <p>
                  <Link
                    to="penal"
                    className="text-secondary text-decoration-none"
                  >
                    Penal
                  </Link>
                </p>
                <p>
                  <Link
                    to="administrativo"
                    className="text-secondary text-decoration-none"
                  >
                    Administrativo
                  </Link>
                </p>
                <p>
                  <Link
                    to="laboral"
                    className="text-secondary text-decoration-none"
                  >
                    Laboral
                  </Link>
                </p>
                <p>
                  <Link
                    to="familia"
                    className="text-secondary text-decoration-none"
                  >
                    Familia
                  </Link>
                </p>
                <p>
                  <Link
                    to="tributario"
                    className="text-secondary text-decoration-none"
                  >
                    Tributario
                  </Link>
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">QUIENES SOMOS</h6>
                <p>
                  <Link
                    to="nosotros"
                    className="text-secondary text-decoration-none"
                  >
                    Mision
                  </Link>
                </p>
                <p>
                  <Link
                    to="nosotros"
                    className="text-secondary text-decoration-none"
                  >
                    Vision
                  </Link>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">CONTACTENOS</h6>
                <p>
                  <i className="fas fa-home me-2"></i> Cr 15 No. 118 - 75 Of.
                  605 Bogotá D.C.
                </p>
                <p>
                  <i className="fas fa-envelope me-2"></i>
                  acostawill@hotmail.com
                </p>
                <p>
                  <i className="fas fa-phone me-2"></i> + 57 310 217 2692
                </p>
                <p>
                  <i className="fas fa-phone me-2"></i> PBX 601 478 5551
                </p>

                <Link to="/consultoriojuridico">
                  <Button className="me-3 btn btn-primary" type="button">
                    CONTACTENOS
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2022 Copyright: Todos los derechos reservados.
          <a className="text-reset fw-bold" href="https://coljuridica.com/">
            COLJURIDICA CONTINENTAL LTDA.
          </a>
        </div>
      </footer>
    </Container>
  );
};

export default FooterWeb;
