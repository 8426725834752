import React from "react";
import { Button, Accordion, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Laboral = () => {
  return (
    <>
      <div className="container w-75">
        <div>
          <h2 className="mt-5 mb-5">DERECHO LABORAL</h2>
          <p className="text-start mb-5">
            Las ramas en que prestamos nuestros servicios en esta área son las
            siguientes:
          </p>
        </div>

        <Accordion>
          <Accordion.Item>
            <Accordion.Header
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-controls="panelsStayOpen-collapseOne"
            >
              DERECHO LABORAL
            </Accordion.Header>
            <Accordion.Body>
              <ul className="text-start">
                <li>
                  Análisis de situaciones y problemáticas al interior del
                  cliente, formulación de alternativas e implementación de
                  soluciones.
                </li>
                <li>Due diligence y auditorías laborales.</li>
                <li>
                  Proyección de documentos: liquidaciones de prestaciones
                  sociales, conciliaciones, transacciones, sustituciones
                  patronales.
                </li>
                <li>
                  Revisión jurídica de la contratación de servicios mediante
                  sistemas diferentes a las relaciones laborales (contratos de
                  prestación de servicios, empresas de servicios temporales,
                  entre otros).
                </li>
                <li>
                  Elaboración o revisión de contratos laborales y de prestación
                  de servicios.
                </li>
                <li>
                  Elaboración o revisión de Reglamentos Internos de Trabajo.
                </li>
                <li>
                  Trámites relacionados conlitigios ante la jurisdicción
                  laboral: definición de estrategias, representación judicial,
                  elaboración de demandas o contestación de demandas, recursos
                  de casación u oposición, entre otros.
                </li>
                <li>
                  Trámites relacionados con tutelas: definición de estrategias,
                  respuestas a requerimientos de juzgados, o tribunales.
                </li>
                <li>
                  Asesoría en Planes de Opciones de Compra de Acciones para
                  empleados y ejecutivos.
                </li>
                <li>Revisión de políticas de personal.</li>
                <li>
                  Capacitaciones sobre temas específicos requeridos por la
                  empresa, en materia de derecho laboral y seguridad social.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              SEGURIDAD INDUSTRIAL
            </Accordion.Header>
            <Accordion.Body>
              <ul className="text-start">
                <li>
                  Análisis de situaciones y problemáticas al interior del
                  cliente, formulación de alternativas e implementación de
                  soluciones.Due diligence y auditorías.
                </li>
                <li>
                  Asesoría en requerimientos adelantados por las entidades de
                  seguridad social y entidades recaudadoras de aportes
                  parafiscales.
                </li>
                <li>Análisis pensionales de empleados o ejecutivos.</li>
                <li>Salud Ocupacional.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion>
          <Accordion.Item>
            <Accordion.Header
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              SALUD OCUPACIONAL
            </Accordion.Header>
            <Accordion.Body>
              <ul className="text-start">
                <li>
                  Análisis de problemáticas relacionadas con el Programa de
                  Salud Ocupacional, Seguridad Industrial y funcionamiento del
                  Comité de Higiene.
                </li>
                <li>Diseño e implementación de programas.</li>
                <li>
                  Due diligence y auditorías (diagnóstico por módulos del
                  contenido y ejecución del Programa de Salud Ocupacional y
                  Seguridad Industrial).
                </li>
                <li>
                  Inspección, análisis y diagnóstico detallado de las
                  condiciones de las áreas de trabajo y de la ejecución de los
                  programas que conciernen a esta área.
                </li>
                <li>
                  Asesoría para la elaboración e inscripción del Reglamento de
                  Higiene y Seguridad Industrial y el COPASO.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Container className="mt-3">
          <Link to="/consultoriojuridico">
            <Button>Contactenos</Button>
          </Link>
        </Container>
      </div>
    </>
  );
};
export default Laboral;
