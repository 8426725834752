import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import { BrowserRouter, Route } from "react-router-dom";
import CarouselImg from "./components/CarouselImg/CarouselImg";
import Somos from "./components/Somos/Somos";
import Civil from "./components/Civil/Civil";
import Penal from "./components/Penal/Penal";
import Administrativo from "./components/Administrativo/Administrativo";
import Laboral from "./components/Laboral/Laboral";
import Familia from "./components/Familia/Familia";
import Tributario from "./components/Tributario/Tributario";
import Consultorio from "./components/Consultorio/Consultorio";
import Footer from "./components/Footer/Footer";
import Portafolio from "./components/Portafolio/Portafolio";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Route exact path={"/"} component={CarouselImg} />
        <Route exact path={"/"} component={Portafolio} />
        <Route path={"/nosotros"} component={Somos} />
        <Route path={"/civil"} component={Civil} />
        <Route path={"/penal"} component={Penal} />
        <Route path={"/administrativo"} component={Administrativo} />
        <Route path={"/laboral"} component={Laboral} />
        <Route path={"/familia"} component={Familia} />
        <Route path={"/tributario"} component={Tributario} />
        <Route path={"/consultoriojuridico"} component={Consultorio} />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
