import React from "react";
import Carousel from "react-bootstrap/Carousel";
import img1 from "./pexels-karolina-grabowska-7876051.jpg";
import img2 from "./pexels-pavel-danilyuk-8112172.jpg";
import img3 from "./pexels-sora-shimazaki-5668772.jpg";
import { Container } from "react-bootstrap";

const CarouselImg = (props) => {
  return (
    <Container className="container-fluid mb-5" style={{ height: "500px" }}>
      <Carousel fade fluid="sm">
        <Carousel.Item>
          <img className="d-block w-100" src={img1} alt="First slide" />
          <Carousel.Caption>
            <h3>
              <b>Coljuridica Continental LTDA.</b>
            </h3>
            <p>
              <b>Soluciones de LEY a su alcance.</b>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={img2} alt="Second slide" />

          <Carousel.Caption>
            <h3>
              <b>Coljuridica Continental LTDA.</b>
            </h3>
            <p>
              <b>Soluciones de LEY a su alcance.</b>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={img3} alt="Third slide" />

          <Carousel.Caption>
            <h3>
              <b>Coljuridica Continental LTDA.</b>
            </h3>
            <p>
              <b>Soluciones de LEY a su alcance.</b>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};

export default CarouselImg;
