import React from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Familia = (props) => {
  return (
    <>
      <div className="container w-75">
        <div>
          <h2 className="mt-5 mb-5">DERECHO DE FAMILIA</h2>
          <p className="text-start mb-5">
            La empresa cuenta con abogados especializados en derecho de familia
            para atender los siguientes procesos:
          </p>

          <div className="container border">
            <div className="row">
              <div className="col m-auto p-auto">
                <h5 className="text-dark ">DIVORCIOS</h5>
              </div>
              <div className="col">
                <ul className="text-start">
                  <li>Divorcio Express</li>
                  <li>Divorcio de mutuo acuerdo</li>
                  <li>Divorcio contencioso</li>
                  <li>Divorcio con o sin hijos</li>
                  <li>Divorcio con o sin bienes</li>
                  <li>Divorcio con o sin embargo de bienes</li>
                  <li>Divorcio con alimentos para cónyuge divorciado</li>
                  <li>Divorcio cuando el cónyuge esta desaparecido</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container border">
            <div className="row">
              <div className="col m-auto p-auto">
                <h5 className="text-dark">
                  SEPARACIÓN DE BIENES UNIONES MARITALES DE HECHO
                </h5>
              </div>
              <div className="col">
                <ul className="text-start">
                  <li>Declaración de Existencia</li>
                  <li>Liquidación y distribución de los bienes</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container border">
            <div className="row">
              <div className="col m-auto p-auto">
                <h5 className="text-dark">
                  SEPARACIONES DE CUERPOS SUCESIONES
                </h5>
              </div>
              <div className="col">
                <ul className="text-start">
                  <li>Notarial</li>
                  <li>Juridica</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container border">
            <div className="row">
              <div className="col m-auto p-auto">
                <h5 className="text-dark">ALIMENTOS</h5>
              </div>
              <div className="col">
                <ul className="text-start">
                  <li>Para menores</li>
                  <li>Para mayores</li>
                  <li>Para los cónyuges</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container border">
            <div className="row">
              <div className="col m-auto p-auto">
                <h5 className="text-dark">DELITOS CONTRA LA FAMILIA</h5>
              </div>
              <div className="col">
                <ul className="text-start">
                  <li>Delitos contra el pudor sexual.</li>
                  <li>Violencia intrafamiliar.</li>
                  <li>Ejercicio ilegal de la custodia</li>
                  <li>Delito de Inasistencia Alimentaria</li>
                  <li>Régimen penal de los menores</li>
                  <li>Sistema penal acusatorio</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container border">
            <div className="row">
              <div className="col m-auto p-auto">
                <h5 className="text-dark">OTROS</h5>
              </div>
              <div className="col">
                <ul className="text-start">
                  <li>Testamentos.</li>
                  <li>Inventario solemne de bienes.</li>
                  <li>Levantamiento de patrimonio de familia.</li>
                  <li>
                    Levantamiento y constitución de afectación a vivienda
                    familiar.
                  </li>
                  <li>Capitulaciones matrimoniales.</li>
                  <li>Adopciones.</li>
                  <li>Donaciones.</li>
                  <li>Permisos de salida del país</li>
                  <li>Restitución internacional de menores</li>
                  <li>Investigación de paternidad</li>
                  <li>Impugnación de paternidad</li>
                  <li>Citación reconocimiento de hijo extramatrimonial</li>
                  <li>Custodia y cuidado personal</li>
                  <li>Regulación de visitas</li>
                  <li>
                    Patria potestad su perdida, suspensión o rehabilitación
                  </li>
                  <li>
                    Interdicciones designación de curador de hijos o hermanos
                    enfermos
                  </li>
                  <li>Declaración de muerte por desaparecimiento</li>
                  <li>
                    Recurso extraordinario de casación ante la corte suprema de
                    justicia
                  </li>
                  <li>Recurso de revisión</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Container className="mt-3">
          <Link to="/consultoriojuridico">
            <Button>Contactenos</Button>
          </Link>
        </Container>
      </div>
    </>
  );
};

export default Familia;
