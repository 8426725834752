import React from "react";
import { Card } from "react-bootstrap";
import img1 from "./pexels-cqfavocat-613508.jpg";
import img2 from "./pexels-ekaterina-bolovtsova-6077326.jpg";

const Somos = (props) => {
  return (
    <>
      <div className="container w-75">
        <div>
          <h2 className="mt-5 mb-5">QUIENES SOMOS</h2>
          <p className="text-start mb-5">
            COLJURIDICA CONTINENTAL LTDA, Es una firma de consultoría y
            acompañamiento jurídico, comprometida con la excelencia profesional
            y académica; integrada por un selecto grupo de juristas, egresados
            de las más prestigiosas universidades. Su profesionalismo y
            experiencia están avalados y comprobados con la capacidad de estudio
            y práctica en las distintas áreas del Derecho. COLJURIDICA
            CONTINENTAL LTDA, recoge el legado de tres generaciones de
            profesionales del derecho destacados por su trayectoria y
            experiencia en la solución de controversias, para fusionarlo con las
            nuevas técnicas para el ejercicio del derecho, como en efecto lo es
            la “Asistencia Jurídica Proactiva” la cual está basada en la fuerte
            convicción de que los conocimientos legales tienen mejor aplicación
            antes de que las cosas salgan mal, filosofía de trabajo que se
            preocupa por garantizar el bienestar de nuestros clientes
            permitiéndoles obtener mayores beneficios en sus actividades
            comerciales, para lo cual nos valemos de los distintos mecanismos
            alternativos para la solución de conflictos.
          </p>
        </div>

        <div className="d-flex flex-wrap justify-content-around mb-5">
          <Card border="dark" className="m-2" style={{ width: "30rem" }}>
            <Card.Img variant="top" src={img1} />
            <Card.Body>
              <Card.Title>MISION</Card.Title>
              <Card.Text>
                Nuestra función y misión han sido y será renovar nuestros
                servicios, de acuerdo a las necesidades latentes de nuestros
                contratantes, para armonizarlas conforme a las nuevas
                directrices jurídicas y tecnológicas de vanguardia, buscando
                proveer al usuario soluciones eficaces, innovadoras, prácticas y
                beneficiosas a los asuntos confiados.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card border="dark" className="m-2" style={{ width: "30rem" }}>
            <Card.Img variant="top" src={img2} />
            <Card.Body>
              <Card.Title>VISION</Card.Title>
              <Card.Text>
                Nuestra visión es consolidarnos como los precursores en la
                prestación de servicios legales altamente calificados y
                competitivos a nivel nacional e internacional, caracterizados
                por el estudio constante y la actualización permanente de los
                temas que acontecen en el universo jurídico. COLJURIDICA
                CONTINENTAL LTDA.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div>
          <h3 className="fst-italic">
            COLJURIDICA CONTINENTAL LTDA. Su mejor aliado
          </h3>
        </div>
      </div>
    </>
  );
};

export default Somos;
