import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Form, Row, Container, Col, Button } from "react-bootstrap";
import Map from "../Map/Map";

const validate = (input) => {
  let errors = {};
  if (!input.user_name) {
    errors.user_name = "Digite su nombre";
  } else if (!input.user_name.match(/^[A-Za-z]+$/)) {
    errors.user_name = "Name must contain only letters";
  }

  if (!input.user_email) {
    errors.user_email = "Digite correo electrónico";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input.user_email)
  ) {
    errors.user_email = "Digite correo electrónico valido";
  }

  if (isNaN(input.user_phone)) {
    errors.user_phone = "Por favor digite números únicamente";
  }
  return errors;
};

export const ContactUs = () => {
  const form = useRef();

  const [input, setInput] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
  });

  const [errors, setErrors] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    const property = e.target.name;
    console.log(value);
    console.log(property);

    setInput({ ...input, [property]: value });
    setErrors(validate({ ...input, [property]: value }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    alert("Información subida exitosamente");
    emailjs
      .sendForm(
        "service_7a1goc6",
        "template_7et19kp",
        form.current,
        "iQqTsv1i_YlPCnOle"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className="container border mt-5">
        <div className="row">
          <div className="col mt-5">
            <div className="p-5">
              <p className="text-start">
                Si desea tener más información acerca de los servicios brindados
                por COLJURIDICA CONTINENTAL LTDA. no dude en visitarnos o
                llamarnos a la línea que encontrara a continuación. Será un
                placer poder ayudarlo. De igual forma puede utilizar nuestro
                formulario en el cual podrá dejar su duda y nos contactaremos de
                forma oportuna.
              </p>
              <p className="text-start">
                <b>Direccion:</b> Cr 15 No. 118 - 75 Of. 605 Bogotá D.C.
              </p>
              <p className="text-start">
                <b>Correo:</b> acostawill@hotmail.com
              </p>
              <p className="text-start">
                <b>Telefono:</b> +57 310 217 2692
              </p>
              <p className="text-start">
                <b>PBX:</b> 601 478 5551
              </p>
            </div>

            <Map />
          </div>
          <div className="col m-5">
            <Container
              style={{ width: "80%" }}
              className="border border-info rounded mt-5"
            >
              <Row>
                <Col>
                  <h1 className="text-info m-4">Contactenos</h1>
                  <hr />
                  <Form ref={form} onSubmit={sendEmail}>
                    <Form.Group>
                      <Form.Label>NOMBRE</Form.Label>

                      <Form.Control
                        type="text"
                        name="user_name"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    {errors.user_name && <p>{errors.user_name}</p>}

                    <Form.Group>
                      <Form.Label className="mt-3">EMAIL</Form.Label>
                      <Form.Control
                        type="email"
                        name="user_email"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    {errors.user_email && <p>{errors.user_email}</p>}

                    <Form.Group>
                      <Form.Label className="mt-3">TELEFONO</Form.Label>
                      <Form.Control
                        type="tel"
                        name="user_phone"
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    {errors.user_phone && <p>{errors.user_phone}</p>}
                    <Form.Group>
                      <Form.Label className="mt-3">MENSAJE</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="message"
                        onChange={handleInputChange}
                      />
                      <div className="m-3">
                        {input.user_name &&
                        input.user_email &&
                        input.user_phone ? (
                          <Button className="btn btn-primary" type="submit">
                            Contactar
                          </Button>
                        ) : (
                          <h5 class="text-warning">
                            Ingrese la información solicitada
                          </h5>
                        )}
                      </div>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
