import React from "react";
import img1 from "./pexels-sora-shimazaki-5669602.jpg";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Civil = (props) => {
  return (
    <>
      <div className="container w-75">
        <div>
          <h2 className="mt-5 mb-5">DERECHO CIVIL</h2>
          <p className="text-start mb-5">
            Está pensado para resolver las necesidades de nuestros clientes y
            aliados en el giro normal de sus negocios y en todos los proyectos
            adelantados o que quieran emprenderse. De esta forma, este
            departamento se encargará de desarrollar, en términos generales, las
            siguientes actividades:
          </p>
          <div className="d-flex flex-wrap justify-content-around">
            <Container className="col-auto">
              <img
                src={img1}
                className="rounded"
                style={{ width: "250px" }}
                alt="law"
              />
            </Container>

            <Container className="col-8">
              <ul className="text-start">
                <li>
                  Asesorías, negociación, acompañamiento y redacción de
                  contratos
                </li>
                <li>Negociaciones y transacciones de acciones</li>
                <li>
                  Consultoría y litigio en asuntos corporativos en general.
                </li>
                <li>
                  Constitución de sociedades, fusiones y adquisiciones,
                  escisiones, transformaciones societarias, acompañamiento
                  jurídico en juntas de socios o asambleas de accionistas.
                </li>
                <li>
                  Administración del patrimonio societario y estructuración de
                  estrategias corporativas que optimicen la administración.
                </li>
                <li>
                  Estructuración de protocolos de familia y patrimonio familiar.
                </li>
                <li>
                  Asesorías en materia de derecho de la competencia atinentes a
                  reclamaciones o defensa por competencia desleal, prácticas
                  restrictivas de la competencia y protección al consumidor
                </li>
                <li>
                  Cualquier otro aspecto relativo al derecho empresarial que
                  usted o su empresa requiera.
                </li>
              </ul>
            </Container>
          </div>
        </div>

        <Link to="/consultoriojuridico">
          <Button>CONTACTENOS</Button>
        </Link>
      </div>
    </>
  );
};

export default Civil;
