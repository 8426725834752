import React from "react";

const Map = (props) => {
  return (
    <>
      <iframe
        title="GoogleMaps"
        width="400"
        height="300"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=coljuridica%20continental&t=&z=19&ie=UTF8&iwloc=&output=embed"
        scrolling="no"
      ></iframe>
    </>
  );
};

export default Map;
