import React from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import img1 from "./tributa.jpg";

const Tributario = () => {
  return (
    <>
      <div className="container w-75">
        <div>
          <h2 className="mt-5 mb-5">
            DERECHO TRIBUTARIO, CONTABLE Y CAMBIARIO
          </h2>
          <p className="text-start mb-5">
            Con el fin de que nuestros clientes puedan contar con asesoría
            óptima en el tema contable, tributario y cambiario, nuestra Firma
            ofrece los siguientes servicios:
          </p>

          <div className="container border rounded p-3">
            <div className="row">
              <div className="col m-auto p-auto">
                <ul className="text-start">
                  <li>Auditoría y due diligence.</li>
                  <li>
                    Planeación fiscal en operaciones nacionales e
                    internacionales.s
                  </li>
                  <li>Zonas Francas.</li>
                  <li>
                    Asesoría tributaria en fusiones, adquisiciones, escisiones y
                    otras reorganizaciones.
                  </li>
                  <li>
                    Planeación tributaria en proyectos de infraestructura,
                    concesiones y privatizaciones.
                  </li>
                  <li>
                    Asesoría legal en todos los aspectos relacionados con
                    impuestos, incluyendo la asesoría contable necesaria para su
                    adecuado tratamiento.
                  </li>
                  <li>
                    Representación en recursos y procesos ante las autoridades
                    de impuestos, así como en procesos ante autoridades
                    judiciales (Tribunales y Consejo de Estado).
                  </li>
                  <li>
                    Preparación y revisión de estudios de precios de
                    transferencia.
                  </li>
                  <li>
                    Planeación patrimonial de personas naturales y de familias.
                  </li>
                  <li>
                    Revisión de declaraciones de renta, retenciones en la
                    fuente, ventas e impuestos municipales.
                  </li>
                  <li>
                    Asesoría en tributación internacional y tratados
                    internacionales.
                  </li>
                  <li>
                    Asesoría sobre regulación cambiaria y manejo de infracciones
                    cambiarias.
                  </li>
                </ul>
              </div>
              <div className="col m-auto p-auto">
                <img src={img1} alt="imaTribu" style={{ width: "400px" }} />
              </div>
            </div>
          </div>

          <Container className="mt-3">
            <Link to="/consultoriojuridico">
              <Button>Contactenos</Button>
            </Link>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Tributario;
